/* Change this file to get your personal Porfolio */

import { link } from "fs";

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Tianle's Portfolio",
  description:
    "Machine learning PhD @Princeton. Life-long learner, hacker, and builder.",
  og: {
    title: "Tianle's Portfolio",
    type: "website",
    url: "https://tianle.website/",
  },
};

//Home Page
const greeting = {
  title: "Tianle Cai",
  logo_name: "Tianle Cai",
  nickname: "",
  subTitle:
    "Machine learning PhD @Princeton. Life-long learner, hacker, and builder.",
  resumeLink:
    "https://drive.google.com/file/d/1FL_wydnPF2SWCdw2goYAgZ1KKBx8TpcD/view?usp=sharing",
  portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/ashutosh1919",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/ctlllll",
    fontAwesomeIcon: "fab fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/tianle-cai-bb7135138/",
    fontAwesomeIcon: "fab fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/c/DevSense19",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Google Scholar",
    link: "https://scholar.google.com/citations?user=CvwLRSMAAAAJ",
    fontAwesomeIcon: "ai ai-google-scholar", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Mail",
    link: "mailto:tianle.cai@princeton.edu",
    fontAwesomeIcon: "far fa-envelope", // Reference https://fontawesome.com/icons/envelope?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/tianle_cai",
    fontAwesomeIcon: "fab fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
  {
    name: "WeChat",
    link: "./contacts/wechat.jpg",
    fontAwesomeIcon: "fab fa-weixin",
    backgroundColor: "#1AAD19",
  },
];

// 1. ## (Preprint) [Large Language Models as Tool Makers](https://arxiv.org/abs/2305.17126)
//    *Tianle Cai, Xuezhi Wang, Tengyu Ma, Xinyun Chen, Denny Zhou*

//     <p align="center">
//         <img src="../images/toolmaker_1.png" width="80%">
//     </p>
//     <p align="center">
//         <img src="../images/toolmaker_2.png" width="80%">
//     </p>

//     **Highlight: Tools can boost the productivity of LLMs, but what if there isn't a suitable tool?-- Let LLM build their own We introduce "LLMs As Tool Maker": one LLM serves as Tool Maker👩🏻‍🎓 to make new tools🔨, another LLM servers as Tool User👨🏻‍🔧 to solve new problems with the tool.**

// 1. ## (ICLR 2023) [What Makes Convolutional Models Great on Long Sequence Modeling?](https://arxiv.org/abs/2210.09298)
//    *Yuhong Li\*, **Tianle Cai\***, Yi Zhang, Deming Chen, Debadeepta Dey*

//    <!-- ![](/images/sgconv1.png)
//    ![](/images/sgconv2.png) -->
//    <p align="center">
//         <img src="../images/sgconv1.png" width="80%">
//     </p>
//     <p align="center">
//         <img src="../images/sgconv2.png" width="80%">
//     </p>

//    **Highlight: We find two simple principles behind the success of global convolutional models on long sequence modeling: 1) Parameterization efficiency; 2) Decaying structure in kernel weights.**

// 1. ## (NeurIPS 2021) [Do Transformers Really Perform Bad for Graph Representation?](https://arxiv.org/abs/2106.05234)

//     *Chengxuan Ying, **Tianle Cai**, Shengjie Luo, Shuxin Zheng, Guolin Ke, Di He, Yanming Shen, Tie-Yan Liu*

//     <!-- ![](/images/graphormer_1.png)
//     ![](/images/graphormer_2.png) -->
//     <p align="center">
//         <img src="../images/graphormer_1.png" width="80%">
//     </p>
//     <p align="center">
//         <img src="../images/graphormer_2.png" width="80%">
//     </p>

//     **Highlight: Make Transformer great again on graph classification by introducing three graph structural encodings! Achieve SOTA performance on several benchmarks! Winner solution of OGB-LSC challenge!!**

//     [[Code]](https://github.com/microsoft/Graphormer)

// 1.  ## (NeurIPS 2021) [Towards a Theoretical Framework of Out-of-Distribution Generalization](https://arxiv.org/abs/2106.04496)

//     *Haotian Ye, Chuanlong Xie, **Tianle Cai**, Ruichen Li, Zhenguo Li, Liwei Wang*

//     <!-- ![](/images/framework_1.png)
//     ![](/images/framework_2.png) -->
//     <p align="center">
//         <img src="../images/framework_1.png" width="80%">
//     </p>
//     <p align="center">
//         <img src="../images/framework_2.png" width="80%">
//     </p>

//     **Highlight: We formulate what an OOD is and derive bounds and model selection algorithm upon our framework.**

// 1. ## (NeurIPS 2021) [Stable, Fast and Accurate: Kernelized Attention with Relative Positional Encoding](https://arxiv.org/abs/2106.12566)

//     *Shengjie Luo, Shanda Li, **Tianle Cai**, Di He, Dinglan Peng, Shuxin Zheng, Guolin Ke, Liwei Wang, Tie-Yan Liu*

//     <!-- ![](/images/kernel_rpe_1.png)
//     ![](/images/kernel_rpe_2.png) -->
//     <p align="center">
//         <img src="../images/kernel_rpe_1.png" width="80%">
//     </p>
//     <p align="center">
//         <img src="../images/kernel_rpe_2.png" width="80%">
//     </p>

//     **Highlight: Enabling fast relative positional encoding and stabilize the training via Fast Fourier Transform.**

// 1.  ## (ICML 2021) [A Theory of Label Propagation for Subpopulation Shift](https://arxiv.org/abs/2102.11203)

//     ***Tianle Cai\***, Ruiqi Gao\*, Jason D. Lee\*, Qi Lei\**

//     <!-- ![](/images/label_prop_1.png)
//     ![](/images/label_prop_2.png) -->
//     <p align="center">
//         <img src="../images/label_prop_1.png" width="80%">
//     </p>
//     <p align="center">
//         <img src="../images/label_prop_2.png" width="80%">
//     </p>

//     **Highlight: Subpopulation shift is a ubiquitous component of natural distribution shift. We propose a general theoretical framework of learning under subpopulation shift based on label propagation. And our insights can help to improve domain adaptation algorithms.**

//     [[Slides]](/files/label_prop_slides.pdf)

// 1.  ## (ICML 2021) [Towards Certifying $\ell_\infty$ Robustness using Neural Networks with $\ell_\infty$-dist Neurons](https://arxiv.org/abs/2102.05363)

//     *Bohang Zhang, **Tianle Cai**, Zhou Lu, Di He, Liwei Wang*

//     <!-- ![](/images/lipnet_1.png) -->
//     <p align="center">
//         <img src="../images/lipnet_1.png" width="80%">
//     </p>

//     **Highlight: New architecture with inherent $\ell_\infty$-robustness and a tailored training pipeline. Achieving SOTA performance on several benchmarks!**

//     [[Code](https://github.com/zbh2047/L_inf-dist-net)]

// 1.  ## (ICML 2021) [GraphNorm: A Principled Approach to Accelerating Graph Neural Network Training](https://arxiv.org/abs/2009.03294)

//     ***Tianle Cai\***, Shengjie Luo\*, Keyulu Xu, Di He, Tie-Yan Liu, Liwei Wang*

//     <!-- ![](/images/graphnorm_1.png)
//     ![](/images/graphnorm_2.png) -->
//     <p align="center">
//         <img src="../images/graphnorm_1.png" width="80%">
//     </p>
//     <p align="center">
//         <img src="../images/graphnorm_2.png" width="80%">
//     </p>

//     **Highlight: A principled normalization scheme specially designed for graph neural networks. Achieve SOTA on several graph classification benchmarks.**

//     [[Code](https://github.com/lsj2408/GraphNorm)], [[Third-part implementation by microsoft ptgnn lab](https://github.com/microsoft/ptgnn). (Thanks for the very quick reaction and implementation MS!)], [[Slides]](/files/graphnorm_slides.pdf)

// 1.  ## (NeurIPS 2020) [Sanity-Checking Pruning Methods: Random Tickets can Win the Jackpot](https://arxiv.org/abs/2009.11094)

//     *Jingtong Su\*, Yihang Chen\*, **Tianle Cai\***, Tianhao Wu, Ruiqi Gao, Liwei Wang, Jason D. Lee*

//     <!-- ![](/images/sanity_1.png) -->
//     <p align="center">
//         <img src="../images/sanity_1.png" width="80%">
//     </p>

//     **Highlight: We sanity-check several existing pruning methods and find the performance of a large group of methods only rely on the *pruning ratio of each layer*. This finding inspires us to design an efficient data-independent, training-free pruning method as a byproduct.**

//     [[Code](https://github.com/JingtongSu/sanity-checking-pruning)]

// 1. ## (NeurIPS 2019 *Spotlight 2.4 % Acceptance rate*) [Convergence of Adversarial Training in Overparametrized Networks](https://arxiv.org/abs/1906.07916)

//     *Ruiqi Gao\*, **Tianle Cai\***, Haochuan Li, Liwei Wang, Cho-Jui Hsieh, Jason D. Lee*

//     <!-- ![](/images/adv_train_1.png) -->
//     <p align="center">
//         <img src="../images/adv_train_1.png" width="80%">
//     </p>

//     **Highlight: For overparameterized neural network, we prove that adversarial training can converge to global minima (with loss 0).**

//     [[Slides](/files/adv_converge_slides.pdf)]

// 1. ## (NeurIPS 2019 Beyond First Order Method in ML Workshop) [Gram-Gauss-Newton Method: Learning Overparameterized Neural Networks for Regression Problems](https://arxiv.org/abs/1905.11675)

//     ***Tianle Cai**\*, Ruiqi Gao\*, Jikai Hou\*, Siyu Chen, Dong Wang, Di He, Zhihua Zhang, Liwei Wang*

//     <!-- ![](/images/ggn_1.png)
//     ![](/images/ggn_2.png) -->
//     <p align="center">
//         <img src="../images/ggn_1.png" width="80%">
//     </p>
//     <p align="center">
//         <img src="../images/ggn_2.png" width="80%">
//     </p>

//     **Highlight: A provable second-order optimization method for overparameterized network on regression problem! As light as SGD at each iteration but converge much faster than SGD for real world application.**

const projects = {
  data: [
    {
      title: "Inference Efficiency of Large Models",
      imagePath: "efficiency.svg",
      publications: [
        {
          title:
            "(ICML 2024) Medusa: Simple Framework for Accelerating LLM Generation with Multiple Decoding Heads",
          author:
            "Tianle Cai*, Yuhong Li*, Zhengyang Geng, Hongwu Peng, Tri Dao",
          highlights:
            "Medusa is a simple framework that democratizes the acceleration techniques for LLM generation with multiple decoding heads. It achieves an impressive speedup of more than 2x.",
          link: "https://github.com/FasterDecoding/Medusa",
          arxiv: "https://arxiv.org/abs/2401.10774",
          code: "https://github.com/FasterDecoding/Medusa",
          blog: "https://sites.google.com/view/medusa-llm",
          video: null,
          slides: null,
        },
        {
          title: "(ICLR 2024) Large Language Models as Tool Makers",
          author: "Tianle Cai, Xuezhi Wang, Tengyu Ma, Xinyun Chen, Denny Zhou",
          highlights:
            "Tools can boost the productivity of LLMs, but what if there isn't a suitable tool?-- Let LLM build their own We introduce `LLMs As Tool Maker`: one LLM serves as Tool Maker👩🏻‍🎓 to make new tools🔨, another LLM servers as Tool User👨🏻‍🔧 to solve new problems with the tool.",
          link: "https://arxiv.org/abs/2305.17126",
          code: "https://github.com/ctlllll/LLM-ToolMaker",
          arxiv: "https://arxiv.org/abs/2305.17126",
        },
        {
          title: "BitDelta: Your Fine-Tune May Only Be Worth One Bit",
          author:
            "James Liu*, Guangxuan Xiao, Kai Li, Jason D. Lee, Song Han, Tri Dao, Tianle Cai*",
          highlights:
            "BitDelta compresses the weight delta between a fine-tuned and base model LLM to 1 bit, enabling accurate and efficient multi-tenant serving.",
          link: "https://arxiv.org/abs/2402.10193",
          arxiv: "https://arxiv.org/abs/2402.10193",
          code: "https://github.com/FasterDecoding/BitDelta",
          blog: "https://fasterdecoding.github.io/BitDelta/",
        },
        {
          title: "(NAACL 2024) REST: Retrieval-Based Speculative Decoding",
          author: "Zhenyu He*, Zexuan Zhong*, Tianle Cai*, Jason D Lee, Di He",
          highlights:
            "REST is a plug-and-play method for accelerating language model decoding without any training. It retrieves common phrases from a datastore and lets models verify them in parallel. ",
          arxiv: "https://arxiv.org/abs/2311.08252",
          blog: "https://sites.google.com/view/rest-llm/",
        },
        {
          title:
            "(CVPR 2024) DistriFusion: Distributed Parallel Inference for High-Resolution Diffusion Models",
          author:
            "Muyang Li*, Tianle Cai*, Jiaxin Cao, Qinsheng Zhang, Han Cai, Junjie Bai, Yangqing Jia, Ming-Yu Liu, Kai Li, Song Han",
          highlights:
            "DistriFusion is a training-free algorithm to harness multiple GPUs to accelerate diffusion model inference without sacrificing image quality.",
          arxiv: "https://arxiv.org/abs/2402.19481",
          blog: "https://hanlab.mit.edu/projects/distrifusion",
          link: "https://arxiv.org/abs/2402.19481",
          code: "https://github.com/mit-han-lab/distrifuser",
        },
      ],
    },
    {
      title: "Long-Context Sequence Modeling",
      imagePath: "long-context.svg",
      publications: [
        {
          title:
            "(ICLR 2023) What Makes Convolutional Models Great on Long Sequence Modeling?",
          author:
            "Yuhong Li*, Tianle Cai*, Yi Zhang, Deming Chen, Debadeepta Dey",
          highlights:
            "We find two simple principles behind the success of global convolutional models on long sequence modeling: 1) Parameterization efficiency; 2) Decaying structure in kernel weights.",
          link: "https://arxiv.org/abs/2210.09298",
          arxiv: "https://arxiv.org/abs/2210.09298",
          code: "https://github.com/ctlllll/SGConv",
        },
        {
          title:
            "(NeurIPS 2021) Stable, Fast and Accurate: Kernelized Attention with Relative Positional Encoding",
          author:
            "Shengjie Luo, Shanda Li, Tianle Cai, Di He, Dinglan Peng, Shuxin Zheng, Guolin Ke, Liwei Wang, Tie-Yan Liu",
          highlights:
            "Enabling fast relative positional encoding and stabilize the training via Fast Fourier Transform.",
          link: "https://arxiv.org/abs/2106.12566",
          arxiv: "https://arxiv.org/abs/2106.12566",
        },
      ],
    },
    {
      title: "Learning with Graph Structure",
      imagePath: "graph.svg",
      publications: [
        {
          title:
            "(NeurIPS 2021) Do Transformers Really Perform Bad for Graph Representation?",
          author:
            "Chengxuan Ying, Tianle Cai, Shengjie Luo, Shuxin Zheng, Guolin Ke, Di He, Yanming Shen, Tie-Yan Liu",
          highlights:
            "Make Transformer great again on graph classification by introducing three graph structural encodings! Achieve SOTA performance on several benchmarks! Winner solution of OGB-LSC challenge!!",
          link: "https://arxiv.org/abs/2106.05234",
          arxiv: "https://arxiv.org/abs/2106.05234",
          code: "https://github.com/microsoft/Graphormer",
        },
        {
          title:
            "(ICML 2021) GraphNorm: A Principled Approach to Accelerating Graph Neural Network Training",
          author:
            "Tianle Cai*, Shengjie Luo*, Keyulu Xu, Di He, Tie-Yan Liu, Liwei Wang",
          highlights:
            "A principled normalization scheme specially designed for graph neural networks. Achieve SOTA on several graph classification benchmarks.",
          link: "https://arxiv.org/abs/2009.03294",
          arxiv: "https://arxiv.org/abs/2009.03294",
          code: "https://github.com/lsj2408/GraphNorm",
        },
      ],
    },
    {
      title: "Distribution Shift",
      imagePath: "distribution-shift.svg",
      publications: [
        {
          title:
            "(NeurIPS 2021) Towards a Theoretical Framework of Out-of-Distribution Generalization",
          author:
            "Haotian Ye, Chuanlong Xie, Tianle Cai, Ruichen Li, Zhenguo Li, Liwei Wang",
          highlights:
            "We formulate what an OOD is and derive bounds and model selection algorithm upon our framework.",
          link: "https://arxiv.org/abs/2106.04496",
          arxiv: "https://arxiv.org/abs/2106.04496",
        },
        {
          title:
            "(ICML 2021) A Theory of Label Propagation for Subpopulation Shift",
          author: "Tianle Cai*, Ruiqi Gao*, Jason D. Lee*, Qi Lei*",
          highlights:
            "Subpopulation shift is a ubiquitous component of natural distribution shift. We propose a general theoretical framework of learning under subpopulation shift based on label propagation. And our insights can help to improve domain adaptation algorithms.",
          link: "https://arxiv.org/abs/2102.11203",
          arxiv: "https://arxiv.org/abs/2102.11203",
        },
      ],
    },
    {
      title: "Miscellaneous",
      imagePath: "misc.svg",
      publications: [
        {
          title:
            "(ICML 2021) Towards Certifying L_inf Robustness using Neural Networks with L_inf-dist Neurons",
          author: "Bohang Zhang, Tianle Cai, Zhou Lu, Di He, Liwei Wang",
          highlights:
            "New architecture with inherent L_inf-robustness and a tailored training pipeline. Achieving SOTA performance on several benchmarks!",
          link: "https://arxiv.org/abs/2102.05363",
          arxiv: "https://arxiv.org/abs/2102.05363",
        },
        {
          title:
            "(NeurIPS 2020) Sanity-Checking Pruning Methods: Random Tickets can Win the Jackpot",
          author:
            "Jingtong Su*, Yihang Chen*, Tianle Cai*, Tianhao Wu, Ruiqi Gao, Liwei Wang, Jason D. Lee",
          highlights:
            "We sanity-check several existing pruning methods and find the performance of a large group of methods only rely on the pruning ratio of each layer. This finding inspires us to design an efficient data-independent, training-free pruning method as a byproduct.",
          link: "https://arxiv.org/abs/2009.11094",
          arxiv: "https://arxiv.org/abs/2009.11094",
        },
        {
          title:
            "(NeurIPS 2019) Convergence of Adversarial Training in Overparametrized Networks",
          author:
            "Ruiqi Gao*, Tianle Cai*, Haochuan Li, Liwei Wang, Cho-Jui Hsieh, Jason D. Lee",
          highlights:
            "For overparameterized neural network, we prove that adversarial training can converge to global minima (with loss 0).",
          link: "https://arxiv.org/abs/1906.07916",
          arxiv: "https://arxiv.org/abs/1906.07916",
        },
      ],
    },
  ],
};

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Experience of working with Computer Vision and NLP projects",
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Indian Institute of Information Technology Kurnool",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "iiitk_logo.png",
      alt_name: "IIITDM Kurnool",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "http://iiitk.ac.in",
    },
    {
      title: "Indiana University Bloomington",
      subtitle: "M.S. in Computer Science",
      logo_path: "iu_logo.png",
      alt_name: "Indiana University Bloomington",
      duration: "2021 - 2023",
      descriptions: [
        "⚡ I have taken varity of courses related to Artificial Intelligence which correspond to Explainable AI, Graph Machine Learning, Computer Vision etc.",
        "⚡ Apart from this, I have also done research assistantship. As part of it, I have worked on creating new algorithms in Graph ML and Network Science.",
        "⚡ During my time at university, I was also associated with multimedia department. As part of it, I have worked on some documentry films and interviews.",
      ],
      website_link: "https://www.indiana.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Education",
      work: true,
      experiences: [
        {
          title: "Peking University",
          // company: "B.S. (Major) Information and Computing Science, School of Mathematical Sciences & (Minor) Computer Science and Technology, EECS",
          // company_url: "https://legatohealthtech.com/",
          logo_path: "pku.svg",
          duration: "2016 - 2020",
          // location: "Hyderabad, Telangana",
          description:
            "B.S. in Information and Computing Science, School of Mathematical Sciences.",
          color: "#0879bf",
        },
        {
          title: "Peking University",
          // company: "B.S. (Major) Information and Computing Science, School of Mathematical Sciences & (Minor) Computer Science and Technology, EECS",
          // company_url: "https://legatohealthtech.com/",
          logo_path: "pku.svg",
          duration: "2017 - 2020",
          // location: "Hyderabad, Telangana",
          description: "B.S. in Computer Science and Technology, EECS (Minor).",
          color: "#0879bf",
        },
        {
          title: "Princeton University",
          // company: "Muffito Incorporation",
          // company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "princeton.svg",
          duration: "2021 - Now",
          // location: "Pune, Maharashtra",
          description: "Ph.D. in Electrical and Computer Engineering.",
          color: "#9b1578",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Microsoft Research",
          // company: "TikTok Inc.",
          // company_url: "https://www.tiktok.com/en/",
          logo_path: "microsoft_logo.jpg",
          duration: "May 2022 - Aug 2022",
          // location: "San Francisco, USA",
          description: "Long-context sequence modeling.",
          color: "#000000",
        },
        {
          title: "Google Deepmind",
          // company: "Delhivery Pvt. Ltd.",
          // company_url: "https://www.delhivery.com/",
          logo_path: "googledeepmind_logo.jpg",
          duration: "Jan 2023 - May 2023",
          // location: "Gurgaon, Haryana",
          description: "Large language models.",
          color: "#ee3c26",
        },
        {
          title: "Together AI",
          // company: "Intel Indexer LLC",
          // company_url:
          // "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "togethercomputer_logo.jpg",
          duration: "Sept 2023 - Dec 2023",
          // location: "Work From Home",
          description: "Efficient large model inference.",
          color: "#0071C5",
        },
        {
          title: "Apple",
          logo_path: "apple_logo.jpg",
          duration: "May 2024 - Aug 2024",
          description: "Vision Pro.",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Fun Projects",
  description:
    "I enjoy building fun projects in my free time. Some of them are here :)",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    locality: "Kanodar",
    country: "IN",
    region: "Gujarat",
    postalCode: "385520",
    streetAddress: "Ambavadi vas",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projects,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
