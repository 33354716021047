import React, { Component } from "react";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
import "./ExperienceList.css";
import { Accordion, Panel } from "baseui/accordion";
import { experience } from "../../portfolio.js";
import { Fade } from "react-reveal";

export default function ExperienceList(props) {
  const theme = props.theme;
  return (
    <div className="experience-accord">
      <Fade bottom duration={1000} distance="20px">
        <h1 className="experience-heading" style={{ color: theme.text }}>
          Experience
        </h1>
      </Fade>
      <Accordion>
        {experience.sections.map((section) => {
          return (
            <Panel
              className="accord-panel"
              title={section["title"]}
              key={section["title"]}
              overrides={{
                Header: {
                  style: () => ({
                    backgroundColor: `${theme.body}`,
                    border: `1px solid`,
                    borderRadius: `5px`,
                    borderColor: `${theme.headerColor}`,
                    marginBottom: `3px`,
                    fontFamily: "Google Sans Regular",
                    fontSize: "24px",
                    color: `${theme.text}`,
                    ":hover": {
                      color: `${theme.secondaryText}`,
                    },
                  }),
                },
                Content: {
                  style: () => ({
                    backgroundColor: `${theme.body}`,
                  }),
                },
              }}
            >
              {section["experiences"].map((experience, index) => {
                return (
                  <ExperienceCard
                    index={index}
                    totalCards={section["experiences"].length}
                    experience={experience}
                    theme={theme}
                  />
                );
              })}
            </Panel>
          );
        })}
      </Accordion>
    </div>
  );
}
