import React from "react";
import "./About.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import FeelingProud from "./FeelingProud";

export default function About(props) {
  const theme = props.theme;
  return (
    <div className="main" id="skills">
      <div className="skills-main-div">
        <Fade left duration={2000}>
          <div className="skills-image-div">
            <FeelingProud theme={theme} />
          </div>
        </Fade>

        <div className="skills-text-div">
          <Fade right duration={1000}>
            <h1 className="skills-heading" style={{ color: theme.text }}>
              About me
            </h1>
          </Fade>
          <Fade right duration={2000}>
            <div>
              <p
                className="subTitle skills-text"
                style={{ color: theme.secondaryText }}
              >
                {/* Hey there! I'm Tianle Cai (蔡天乐, pronounced Tyen-luh Tseye), a PhD student at Princeton University, where I work with Professors [Kai Li](https://www.cs.princeton.edu/~li/) and [Jason D. Lee](https://jasondlee88.github.io/).

                                Before Princeton, I got my undergrad degree at Peking University, majoring in applied mathematics and also double-majoring in computer science. Under the guidance of Professor [Liwei Wang](http://www.liweiwang-pku.com/), I started my research on machine learning, an amazing realm. I also worked closely with Professor [Di He](https://www.microsoft.com/en-us/research/people/dihe/).

                                During my PhD, I was fortunate to take a few part-time internships: at Google Deepmind under the guidance of [Xuezhi Wang](https://research.google/people/105995/) and [Denny Zhou](https://dennyzhou.github.io/), and at Microsoft Research with advisors [Sébastien Bubeck](http://sbubeck.com/) and [Debadeepta Dey](https://www.debadeepta.com/).

                                My academic interests span across a wide spectrum within machine learning, including optimization, representation learning, architecture design (with focus on Transformer, Graph Neural Networks, and so on), and more recently, system-architecture co-design. My overarching mission is to advance the generalization [1, 3, 4, 6], efficiency [2, 5, 8, 9, 11], and reliability [7, 10] of machine learning (please refer to my listed publications below).

                                If you share common interests, are interested in potential collaboration, or simply want to connect for a chat, feel free to contact me via e-mail or WeChat. I'm always open to conversation :) */}
                {/* Convert markdown to html: */}
                <p>
                  Hey there! I'm Tianle Cai (蔡天乐, pronounced Tyen-luh Tseye),
                  a PhD candidate at Princeton under Professors{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="https://www.cs.princeton.edu/~li/"
                  >
                    Kai Li
                  </a>{" "}
                  and{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="https://jasondlee88.github.io/"
                  >
                    Jason D. Lee
                  </a>
                  . I completed my undergrad at Peking University in applied
                  mathematics and computer science, guided by Professors{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="http://www.liweiwang-pku.com/"
                  >
                    Liwei Wang
                  </a>{" "}
                  and{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="https://www.microsoft.com/en-us/research/people/dihe/"
                  >
                    Di He
                  </a>
                  .
                </p>
                <p>
                  I was a part-time researcher at{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="https://together.ai/"
                  >
                    Together.ai
                  </a>{" "}
                  working with{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="https://tridao.me/"
                  >
                    Tri Dao
                  </a>
                  . I've also worked at Google Deepmind with{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="https://research.google/people/105995/"
                  >
                    Xuezhi Wang
                  </a>{" "}
                  and{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="https://dennyzhou.github.io/"
                  >
                    Denny Zhou
                  </a>
                  , and at Microsoft Research with{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="http://sbubeck.com/"
                  >
                    Sébastien Bubeck
                  </a>{" "}
                  and{" "}
                  <a
                    style={{ color: theme.secondaryText }}
                    href="https://www.debadeepta.com/"
                  >
                    Debadeepta Dey
                  </a>
                  .
                </p>
                <p>
                  My research recently focuses on designing more efficient
                  systems for large models with system-architecture co-design.
                  I'm also broadly interested in network architecture design,
                  representation learning, and optimization.
                </p>
                <p>
                  If you share common interests, are interested in potential
                  collaboration, or simply want to connect for a chat, feel free
                  to contact me. I'm always open to conversation :)
                </p>
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
