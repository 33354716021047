import React, { Component } from "react";
import "./Publications";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { projects } from "../../portfolio";
import { Fade } from "react-reveal";
import DataScienceImg from "./DataScienceImg";
import FullStackImg from "./FullStackImg";
import CloudInfraImg from "./CloudInfraImg";
import DesignImg from "./DesignImg";
import { Accordion, Panel } from "baseui/accordion";

function GetSkillSvg(props) {
  if (props.fileName === "DataScienceImg")
    return <DataScienceImg theme={props.theme} />;
  else if (props.fileName === "FullStackImg")
    return <FullStackImg theme={props.theme} />;
  else if (props.fileName === "CloudInfraImg")
    return <CloudInfraImg theme={props.theme} />;
  return <DesignImg theme={props.theme} />;
}

class PublicationSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div>
        {projects.data.map((project) => {
          return (
            <div className="skills-main-div">
              <Fade left duration={2000}>
                <div className="skills-image-div">
                  {/* <img
                    alt="Ashutosh is Analysing Data"
                    src={require(`../../assests/images/${skill.imagePath}`)}
                  ></img> */}
                  {/* <GetSkillSvg fileName={project.fileName} theme={theme} /> */}
                  <img
                    alt="figure"
                    src={require(`../../assests/images/${
                      project.imagePath ? project.imagePath : "efficiency.svg"
                    }`)}
                    className="skills-image"
                  ></img>
                </div>
              </Fade>

              <div className="skills-text-div">
                <Fade right duration={1000}>
                  <h1
                    className="skills-heading"
                    style={{ color: theme.jacketColor }}
                  >
                    {project.title}
                  </h1>
                </Fade>
                <Fade right duration={1500}>
                  <div>
                    {project.publications.map((publication) => {
                      return (
                        <p
                          className="subTitle skills-text"
                          style={{ color: theme.secondaryText }}
                        >
                          {/* example: {
                            title: "Medusa",
                            author: "Tianle Cai",
                            highlights: "Accelerate inference",
                            arxiv: null,
                            code: null,
                            blog: null,
                            video: null,
                            slides: null,
                          }, */}
                          <h3>
                            <a
                              style={{ color: theme.text }}
                              href={publication.link}
                            >
                              {publication.title}
                            </a>
                          </h3>
                          <i>{publication.author}</i>
                          <p>
                            <b>🌟Highlight:</b> {publication.highlights}
                          </p>
                          <p>
                            {publication.arxiv && (
                              <a
                                style={{ color: theme.secondaryText }}
                                href={publication.arxiv}
                              >
                                [<i class="ai ai-arxiv"></i>arXiv]
                              </a>
                            )}
                            &nbsp;
                            {publication.code && (
                              <a
                                style={{ color: theme.secondaryText }}
                                href={publication.code}
                              >
                                [🖥️Code]
                              </a>
                            )}
                            &nbsp;
                            {publication.blog && (
                              <a
                                style={{ color: theme.secondaryText }}
                                href={publication.blog}
                              >
                                [📖Blog]
                              </a>
                            )}
                            &nbsp;
                            {publication.video && (
                              <a
                                style={{ color: theme.secondaryText }}
                                href={publication.video}
                              >
                                [📹Video]
                              </a>
                            )}
                            &nbsp;
                            {publication.slides && (
                              <a
                                style={{ color: theme.secondaryText }}
                                href={publication.slides}
                              >
                                [📄Slides]
                              </a>
                            )}
                          </p>
                        </p>
                      );
                    })}
                  </div>
                </Fade>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PublicationSection;
